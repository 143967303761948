import {
  FETCH_ALQUILERES_REQUEST,
  FETCH_ALQUILERES_SUCCESS,
  FETCH_ALQUILERES_FAILURE,
  DELETE_ALQUILERES_SUCCESS,
  DELETE_ALQUILERES_FAILURE,
} from "../actions/types";

const initialState = {
  loading: false,
  alquileres: [],
  error: "",
};

const alquileresReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_ALQUILERES_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case FETCH_ALQUILERES_SUCCESS:
      return {
        loading: false,
        alquileres: action.payload,
        error: "",
      };
    case FETCH_ALQUILERES_FAILURE:
      return {
        loading: false,
        alquileres: [],
        error: action.payload,
      };

    case DELETE_ALQUILERES_SUCCESS:
      return {
        ...state,
        loading: false,
        alquileres: state.alquileres.filter(
          (alquiler) => alquiler._id !== action.payload
        ),
      };

    case DELETE_ALQUILERES_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

export default alquileresReducer;
